<template>
  <b-row>
    <b-col cols="12">
      <h1 class="mt-5 mb-5">Rx Requests</h1>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "requests",
  data() {
    return {};
  },
  mounted() {
    window.analytics.page();
  },
};
</script>
