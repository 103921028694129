var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { cols: "12" } }, [
        _c("h1", { staticClass: "mt-5 mb-5" }, [_vm._v("Rx Requests")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }